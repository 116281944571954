window.onscroll = function() {showStickyNav()};

let navbar = document.querySelector(".navbar-content");

let sticky = navbar.offsetTop;

function showStickyNav() {
  if (window.pageYOffset >= sticky) {
    navbar.classList.add("sticky")
  } else {
    navbar.classList.remove("sticky");
  }
}